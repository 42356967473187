.Backwards {
    z-index: 2;
    color: midnightblue;
    min-width: 10vw;
    padding: 1em;
    margin: 1em;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media screen and (max-aspect-ratio: 4/5) {
    .Backwards {
        margin: 0;
        align-items: flex-start;
        justify-content: flex-start;
    }
}