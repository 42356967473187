.Landing {
    z-index: 6;
    color: midnightblue;
    grid-row-start: 1;
    grid-row-end: 200;
    grid-column-start: 1;
    grid-column-end: 100;
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

h1 {
    font-size: 4em;
    margin: 0;
    padding: 0;
}

input {
    margin: 0.5em;
    padding: 0.5em;
    border-radius: 25vw 2vw 25vw 2vw/2vw 25vw 2vw 25vw;
    border-style: none;
    background: lightgrey;
    color: midnightblue;
    text-align: center;
    font-size: 1em;
    width: 10em;
}

#lobbyMessage {
    font-size: 2em;
    padding: 0.5em;
}

.lobbyButton {
    cursor: pointer;
    font-size: 1.5em;
    width: 5em;
    margin:  0.25em;
    padding: 0.25em 0em;
    border-radius: 25vw 2vw 25vw 2vw/2vw 25vw 2vw 25vw;
}

.hidden {
    display: none;
}

#joinGame {
    background-color: forestgreen;
}

#enterGame {
    background-color: gold;
}

#startGame {
    background-color: gold;
}

@media screen and (max-aspect-ratio: 4/5) {
    input {
        font-size: 1.5em;
    }

    #instructions {
        font-size: 1.5em;
    }
}