.Hazards {
    color: midnightblue;
    width: 100%;
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    flex-wrap: wrap;
}

.hazardSymbol {
    font-size: 1.5em;
}