.Endgame {
    z-index: 3;
    color: midnightblue;
    background-color: beige;
    grid-row-start: 1;
    grid-row-end: 200;
    grid-column-start: 1;
    grid-column-end: 100;
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}