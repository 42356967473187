.Card {
    z-index: 1;
    background-image: linear-gradient(0deg, goldenrod, 95%, goldenrod, 95%, beige);
    color: midnightblue;
    margin: 1em;
    padding: 1em;
    border-radius: 0vw 0vw 10vw 10vw/0vh 0vh 90vh 90vh;
    border: 1em lightblue solid;
    border-top: none;
    text-align: center;
    display: grid;
    grid-template-rows: auto 3fr 1fr auto 1fr;
    grid-template-columns: 1fr 3fr 1fr;
}

.Title {
    grid-row: 3;
    grid-column: 2;
    font-size: 1.5em;
    font-weight: bold;
}

.FlavourText {
    font-size: 0.75em;
    grid-row: 4;
    grid-column-start: 2;
    grid-column-end: 3;
    font-weight: bold;
}

#score {
    font-size: 1em;
    grid-row: 5;
    grid-column: 2;
    justify-content: center;
    align-items: center;
}

#stats {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 4;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

#image {
    grid-row: 2;
    grid-column-start: 1;
    grid-column-end: 4;
    font-size: min(20vh, 10em);
}

@media screen and (max-aspect-ratio: 4/5) {
    .Card {
        margin-top: 5em;
        border-radius: 0vw 0vw 30vw 30vw/0vh 0vh 90vh 90vh;
    }

    .Title {
        font-size: 3em;
    }

    .FlavourText {
        font-size: 1.5em;
    }

    .Value {
        font-size: 1.5em;
    }

    #score {
        font-size: 2em;
    }
}