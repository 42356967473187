.Yeah {
    z-index: 5;
    background-color: rgba(0,0,0,0);
    color: forestgreen;
    font-size: 5em;
    text-align: center;
}

.Nah {
    z-index: 5;
    background-color: rgba(0,0,0,0);
    color: gold;
    font-size: 5em;
    text-align: center;
}

.Wait {
    z-index: 4;
    background-color: beige;
    font-size: 5em;
    text-align: center;
}