.centered {
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grey {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
}

.number {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.head {
  font-size: 3em;
}

.setup {
  visibility: hidden;
}

.play {
  visibility: hidden;
}

.endgame {
  visibility: hidden;
}

.invisible {
  opacity: 0;
}

.visible {
  opacity: 1;
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.selected {
  border-radius: 20px;
  background: white;
}

.tip {
  width: 100%;
  font-size: 0.75em;
  font-weight: bold;
}

.App {
  background-color: beige;
  height: 100vh;
  max-height: 100vh;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: calc(3vh);
  display: grid;
}

@media screen and (max-aspect-ratio: 4/5) {
  .App {
    max-height: calc(90vh - 70px);
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    font-size: calc(3vw);
    flex-direction: column-reverse;
  }

  .Backwards {
    grid-row: 1;
    grid-column: 1;
    flex-direction: column-reverse;
  }

  .Forwards {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 3;
    flex-direction: column-reverse;
  }

  .Hazards {
    padding-bottom: 1em;
  }

  .Card {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .Yeah {
    grid-column: 2;
    grid-row-start: 1;
    grid-row-end: 4;
  }

  .Nah {
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: 4;
  }

  .Wait {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
  }

  #showScore {
    z-index: 5;
    visibility: visible;
    position: fixed;
    width: 6em;
    height: 6em;
    padding: 0;
    margin: 0;
    bottom: 0;
    left: 0;
  }
  
  #showHistory {
    z-index: 5;
    visibility: visible;
    position: fixed;
    width: 6em;
    height: 6em;
    padding: 0;
    margin: 0;
    bottom: 0;
    right: 0;
  }

  .mobileHide {
    display: none;
  }

  .mobileInvis {
    visibility: hidden;
  }

  .head {
    font-size: 10vw;
  }
}

@media screen and (min-aspect-ratio: 4/5) {
  .App {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .Backwards {
    grid-row: 1;
    grid-column: 1;
    flex-direction: column;
  }

  .Forwards {
    grid-row: 1;
    grid-column: 4;
    flex-direction: column;
  }

  .Card {
    grid-row: 1;
    grid-column-start: 2;
    grid-column-end: 4;
    min-width: vh;
  }

  .Yeah {
    grid-row: 1;
    grid-column-start: 3;
    grid-column-end: 5;
  }

  .Nah {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .Wait {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row: 1
  }

  #showScore {
    display: none;
  }
  
  #showHistory {
    display: none;
  }
}