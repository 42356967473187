body {
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: beige;
}

li {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
}

@media screen and (max-aspect-ratio: 4/5) {
  #root {
    max-height: calc(90vh - 70px);
  }
}
