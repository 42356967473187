.Player {
    color: midnightblue;
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font-weight: bold;
}

.meeple {
    min-height: 1.5em;
    min-width: 1.5em;
    max-width: 10em;
    margin: 0.5em;
    margin-left: 0;
    font-size: 0.75em;
}

@media screen and (max-aspect-ratio: 4/5) {
    .Player {
        font-size: 1.25em;
    }
    
    .meeple {
        font-size: 1em;
    }
}